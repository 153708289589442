<template>
  <div>
    <!-- <div class="tabbar">
      <span :class="current == 1 ? 'active' : ''" @click="changeTab(1)"
        >疫情动态</span
      >
      <span :class="current == 2 ? 'active' : ''" @click="changeTab(2)"
        >人员信息</span
      >
    </div> -->
    <!-- 疫情动态 -->
    <section v-if="current == 1" class="">
      <!-- 疫情展示 -->
      <section class="display-content" >
        <!-- 展示内容 -->
        <div class="display-content_detail">
          <div class="databoard" style="box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);">
            <div>
              <h5>{{ updateNum }}</h5>
              <p>今日确诊</p>
            </div>
            <div>
              <h5>{{ nowNum }}</h5>
              <p>现存确诊</p>
            </div>
            <div>
              <h5>{{ totalNum }}</h5>
              <p>累计确诊</p>
            </div>
            <div>
              <h5>{{ curedNum }}</h5>
              <p>累计治愈</p>
            </div>
          </div>
          <ul class="table">
            <li class="thead">
              <span>国家</span>
              <span>总数</span>
            </li>
            <template v-for="item in listData" v-if="item.children.length">
              <h5 :ref="item.letter">#{{ item.letter }}</h5>
              <li class="tr" v-for="item in item.children" @click="goDetail(item.name)">
                <span>{{ item.name }}</span>
                <span>{{ item.value }}</span>
              </li>
            </template>
          </ul>
        </div>
      </section>
    </section>
    <!-- 字母表 -->
    <div class="letters">
      <a @click="scrollTop(item)" v-for="item in letters">{{item}}</a>
    </div>
  </div>
</template>

<script>
import world from "@/components/common/world.vue";
import china from "@/components/common/china.vue";
import linechart from "@/components/common/line.vue";
import webview from "@/mixins/webview";
import { worldEpidemic, chinaEpidemic, chinaEpidemicData,getAllcounrtyData ,EpidemicCount} from "@/api/api";
const letters = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];
export default {
  data() {
    return {
      current: 1, //1->疫情动态;2->人员信息
      displayTab: 1, //1->海外疫情;2->中国疫情
      mapData: [],
      listData: [],
      updateNum: 0,
      totalNum: 0,
      curedNum: 0,
      nowNum: 0,
      lineData: [],
      chinaDead: 0,
      chinaCurrent: 0,
      chinaTotal: 0,
      chinaUpdate: 0,
      target: "",
      chinaData: [],
      chinaListData: [],
      countries: [],
      letters
    };
  },
  mounted() {
    this.getData();
    this.isWebView()
    // this.getLineData();
  },
  methods: {
    async getData() {
      let res = await worldEpidemic();
      let listData = letters.map((item) => {
        return {
          letter: item,
          children: [],
        };
      });
      this.updateNum = res.data.data.updateNum;
      this.nowNum = res.data.data.nowNum;
      this.totalNum = res.data.data.totalNum;
      this.curedNum = res.data.data.curedNum;
      let result = await EpidemicCount()
      result.data.data.forEach((item) => {
        let letter = this.getCnChar(item.name);
        let idx = listData.findIndex((item) => item.letter == letter);
        listData[idx].children.push(item);
      });
      this.listData = listData;
    },
    async getLineData() {
      let res = await chinaEpidemic();
      this.lineData = res.data.data;
    },
    getCnChar(str) {
      return str.spell().substr(0, 1);
    },
    goDetail(name){
      this.$router.push('/management/person?resident='+name)
    },
    scrollTop(id){
      if(this.$refs[id].length){
        let top = this.$refs[id][0].offsetTop
        window.scrollTo(0,top)
      }
    }
  },
  components: {
    world,
    linechart,
    china,
  },
  mixins:[webview]
};
</script>

<style scoped>
.tabbar {
  display: flex;
  background: #fff;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  padding: 0 0.1rem;
  justify-content: space-around;
}
.tabbar span {
  font-size: 0.17rem;
  padding: 0.1rem 0.07rem 0.1rem;
}
.tabbar span.active {
  color: #2f7dcd;
  font-weight: bold;
  border-bottom: 3px solid #2f7dcd;
}
.content {
  padding: 0rem 0.18rem 0.12rem;

background: #FAFAFA;
}
.policySearch {
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  padding-bottom: 0.1rem;
}
.policySearch h5 {
  color: #999;
  padding: 0.07rem 0.17rem;
  font-size: 0.15rem;
  font-weight: normal;
}
.searchBox {
  position: relative;
  padding: 0 0.12rem;
}
.searchBox input {
  background: #f2f2f2;
  height: 0.4rem;
  width: 100%;
  border-radius: 5px;
  outline: none;
  border: none;
  padding: 0 0.2rem;
  font-size: 0.15rem;
  color: #666;
}
.searchBox .weui-btn {
  position: absolute;
  width: 0.6rem;
  height: 0.24rem;
  top: 50%;
  right: 0.25rem;
  transform: translateY(-50%);
  line-height: 0.24rem;
  padding: 0;
  font-size: 0.13rem;
}
.display-content {
  /* margin-top: 0.3rem; */
  /* background: #fff; */
}
.epidemic-tabbar {
  display: flex;
}
.epidemic-tabbar > span {
  flex: 1;
  line-height: 0.4rem;
  color: #232425;
  text-align: center;
  font-size: 0.16rem;
  background: #f2f2f2;
}
.epidemic-tabbar > span:first-child {
  border-right: 1px solid #707070;
}
.epidemic-tabbar > span.active {
  background: #fff;
}
.display-content_detail {
  padding: 0.2rem 0.1rem;
}
.databoard {
  display: flex;
  flex-wrap: wrap;
  background: #fff;
  padding: 0.1rem 0;
  border-radius: 5px;
}
.databoard > div {
  width: 50%;
  text-align: center;
  margin-bottom: 10px;
}
.databoard > div > h5 {
  font-size: 0.2rem;
}
.databoard > div > p {
  font-size: 0.12rem;
  background: #f6f7fd;
  display: inline-block;
  line-height: 0.2rem;
  padding: 0 0.1rem;
  border-radius: 0.1rem;
}
.databoard > div:first-child h5 {
  color: #4861c7;
}
.databoard > div:nth-child(2) h5 {
  color: #cf2b1e;
}
.databoard > div:nth-child(3) h5 {
  color: #4ba185;
}
.databoard > div:nth-child(4) h5 {
  color: #051a3c;
}
.world-map {
  border: 1px dashed #ccc;
  width: 100%;
  height: 200px;
  overflow: hidden;
  margin-top: 0.1rem;
}
.table {
  list-style: none;
  margin-top: 0.2rem;
}
.table li {
  padding: 7px 5px;
  display: flex;
  justify-content: space-between;
}
.thead {
  background: rgba(79, 119, 170, .1)
}
.table li span {
  font-size: 0.13rem;
  color: #333;
  flex: 1;
  text-align: center;
}

.table h5{
  background: rgba(79, 119, 170, .1);
  font-weight: normal;
  color: #666666;
  padding: 3px 5px;
  margin-top: 0.05rem;
}
.letters{
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 0.3rem;
  display: flex;
  flex-direction: column;
  font-size: 0.14rem;
}
.letters a{margin: 1px 0;color: #666;}
</style>