<template>
  <div class="echarts">
    <div
      :class="className"
      :id="id"
      :style="{ height: height, width: width }"
      ref="myEchart"
    ></div>
    <!-- <Title :title="title"></Title>
    <Search :placeholder="placeholder" :find="find" @listenSearch="searchItem" @listenAdd="addNew" @listenLeadIng="leadingItem"></Search> -->
    <!-- <div id="provinceChart" class="charts" ref="myEchart1" style="height:400px;"></div> -->
  </div>
</template>
<script>
import echarts from "echarts";
import "@/lib/map/simple/js/china.js";
// import '../../node_modules/echarts/map/js/china.js' // 引入中国地图数据

export default {
  props: {
    className: {
      type: String,
      default: "char",
    },
    id: {
      type: String,
      default: "chart",
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "200px",
    },
    mapData:{
        type:Array,
        default:()=> []
    },
    nameMap:{
        type:Object,
        default:()=>{}
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  mounted() {
    this.initChart();
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart() {

        console.log(this.mapData)
      this.chart = echarts.init(this.$refs.myEchart);
      window.onresize = echarts.init(this.$refs.myEchart).resize;
      // 把配置和数据放这里
      this.chart.setOption({
        backgroundColor: "#fff",
        visualMap: {
          left: "left",
          min: 0,
          max:3000,
          inRange: {
            color: ["yellow","red"],
          },
          text: ["", ""], // 文本，默认为数值文本
          calculable: false,
          itemHeight: 50,
          itemWidth: 10,
        },
        tooltip: {
          trigger: "item",
        },
        geo: { // 这个是重点配置区
            map: 'china', // 表示中国地图
            roam: true,
            label: {
              normal: {
                show: false, // 是否显示对应地名
              }
            },
            itemStyle: {
              normal: {
                borderColor: 'rgba(0, 0, 0, 0.2)'
              },
              emphasis: {
                areaColor: null,
                shadowOffsetX: 0,
                shadowOffsetY: 0,
                shadowBlur: 20,
                borderWidth: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          },
        series: [
          {
            type: "map",
            mapType: "china",
            zoom: 1.3,
            mapLocation: {
              y: 100,
            },
            data: this.mapData,
            symbolSize: 12,
            label: {
              normal: {
                show: false,
              },
              emphasis: {
                show: false,
              },
            },
            itemStyle: {
              emphasis: {
                borderWidth: 1,
              },
            },
          },
        ],
      });
    },
  },
};
</script>