<template>
  <div id="lineChart" ></div>
</template>

<script>
import echarts from "echarts";
export default {
    props:{
        data:{
            type:Array,
            default:()=>[]
        }
    },
    mounted(){
        this.transformData(this.data)
         let myChart = echarts.init(document.getElementById('lineChart'));
         let options = {
             xAxis: {
                type: 'category',
                data: this.xAxis
            },
            yAxis: {
                type: 'value'
            },
            grid:{
                top:"10%",
                right:'1%',
                left:"20%",
                bottom:"10%"
            },
            color:['yellow','red'],
            series: [{
                data: this.numData,
                type: 'line'
            },{
                data: this.deadData,
                type: 'line'
            }]
         }
         myChart.setOption(options)
    },
    methods:{
        transformData(obj){
            this.xAxis = obj.map(item=>{
                return item.time
            })
            this.numData = obj.map(item=>{
                return item.todayNum
            })
            this.deadData = obj.map(item=>{
                return item.todayDead
            })
        }
    }
}
</script>

<style scoped>
#lineChart{
    width: 100%;
    height: 200px;
}
</style>